// when deploy this file will be replaced with real configuration
window.VSM_CONFIG = {
  GOTHUMB_SERVER_URL: 'http://localhost:8866',
  NODE_ENV: 'development',
  BYPASS_USER: true,
  DEBUG: true,
  LOG_STORE_SIZE: false, //warning: this will slow down the app whenever there is an audit log action.
  // API_KEY: 'the-key-that-whoever-will-not-guess',
  // SERVER_URL: '//valuable-url-you-can-not-afford',
  API_KEY: '35dbd20e-16f4-4b46-ac57-74927283af7a',
  MIXPANEL_TOKEN: 'fa1b48faf4cec00ed51d77952862dd75',
  API_KEYS_LOCAL: {
    // for local debug only
    'MEMBER:Member': '66813777-fd5f-4eb6-b063-71a8aeb92784',
    'PROVIDER:Nurse': '5fc85c1c-5a81-4d11-a807-ba2f032e8c37',
    'PROVIDER:Doctor': '5fc85c1c-5a81-4d11-a807-ba2f032e8c37',
    'PROVIDER:Medical Assistant': '5fc85c1c-5a81-4d11-a807-ba2f032e8c37',
    'PROVIDER:Clinic Medical Assistant': '5fc85c1c-5a81-4d11-a807-ba2f032e8c37',
    'ADMIN:Admin': '5fc85c1c-5a81-4d11-a807-ba2f032e8c37',
    'SUPER_ADMIN:Super Admin': '5fc85c1c-5a81-4d11-a807-ba2f032e8c37',
    'OTHER:Vendor': 'e209bc58-3a8c-439c-b0a5-af1735f81c50',
  },
  MIXPANEL_ENV_CHECK: true,
  BYPASS_USERS_OBJ: {
    test_admin: 'Admin',
    super_admin: 'SuperAdmin',
    yaoming: 'Nurse',
    p774489ec4oaq1: 'Patient',
    test_vendor: 'Vendor',
  },
  VSM_US: {
    BG: {
      unit: 'mg/dL',
    },
    HS: {
      unit: 'lbs',
    },
    HEIGHT: {
      unit_ft: 'ft',
      unit_inches: 'inches',
    },
    date: {
      L: 'MM-DD-YYYY',
    },
    time: {
      LT: 'h:mm A',
      LTS: 'h:mm:ss A',
    },
  },
  DEMO: 'iHealth ShareCare',
};
